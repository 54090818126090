@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body {
    font-family: 'Open Sans', cursive;
}
html {
    p {
        user-select: none;
    }
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 14px;
    box-sizing: border-box;
}
* {
    box-sizing: border-box;
}
.sidebar {
    background: white;
    width: 100%;
    height: 100%;
    transition: none !important;
}

.App {
    &--logos {
        z-index: 1;
        position: fixed;
        top: 5%;
    }

    &--menu {
        position: fixed;
        margin-left: 10%;
    }

    &--logo {
        position: fixed;
        right: 10%;
        top: 8%;
        width: 112px;
        animation-name: example;
        animation-duration: 3s;
    }
    @keyframes example {
        0% {
            opacity: 0;
            transform: translate3d(0, -100%, 0);
        }
        100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    &--logo:hover {
        cursor: pointer;
    }

    &--bar {
        z-index: 2;
        &--content {
            &--emptyrow {
                height: 70px;
            }
            &--closeMenu {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                img {
                    width: 100px;
                    margin: 40px;
                }
                img:hover {
                    cursor: pointer;
                }
            }

            &--index {
                font-size: 12px;
                letter-spacing: 0.5px;
                padding-top: 5px;
                color: #151823;
            }

            &--listElem {
                font-size: 24px;
                letter-spacing: 20px;
                color: #151823;
                margin-left: 10px;
                font-weight: 600;
            }
            &--listElem:hover {
                cursor: pointer;
                color: orange;
            }

            &--row {
                padding: 10px;
                margin-left: 100px;
            }

            &--media {
                width: 100%;
                margin: 0px;
                p {
                    font-size: 12px;
                    font-weight: 600;
                }
                display: flex;
                flex-direction: column;
                &--terms {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 150px;
                    p:hover {
                        color: orange;
                        cursor: pointer;
                    }
                }
                

                hr {
                    border: 0.7px solid orange;
                    width: 100%;
                    margin-bottom: 20px;
                    animation-name: exampleHrOrange;
                    animation-duration: 1s;
                }

                @keyframes exampleHrOrange {
                    0% {
                        opacity: 1;
                        transform: translate3d(0, -1000%, 0);
                    }
                    100% {
                        opacity: 1;
                        transform: translate3d(0, 0, 0);
                    }
                }

                &--social1 {
                    display: flex;
                    flex-direction: row;
                    p {
                        letter-spacing: 6px;
                    }
                }
                &--social2 {
                    animation-name: example;
                    animation-duration: 1s;
                    img {
                        margin-right: 20px;
                        height: 25px;
                    }
                }
            }

            &--wrapper {
                p {
                    animation-name: hideALittle;
                    animation-duration: 1s;
                }
            }
        }

        @keyframes hideALittle {
            0% {
                transform: translate3d(0, -300%, 0);
                opacity: 0;
            }
            100% {
                transform: translate3d(0, 0, 0);
                opacity: 1;
            }
        }

        &--contact {
            font-size: 24px;
            letter-spacing: 2.71px;
        }

        &--contact:hover {
            cursor: pointer;
        }
    }

    &--questions {
        display: flex;
        justify-content: flex-end;
        margin-right: 10%;
        width: 100%;
        p {
            z-index: 100;
            margin-left:20px;
            font-size: 18px;
            letter-spacing: 15px;
            font-weight: bold;
        }
        animation-name: example2;
        animation-duration: 1s;
    }
    @keyframes example2 {
        0% {
            opacity: 0;
            transform: translate3d(0, 100%, 0);
        }
        100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    &--modal {
        height: 100%;
        width: 100%;
        overflow-y: 'auto'
    }

    &--cookies {
        background-color: rgba($color: #000000, $alpha: .9);
        z-index: 1;
        display: flex;
        width: 100%;
        position: fixed;
        bottom: 45px;
        align-items: center;
        p {
            color: white;
        }
        p:hover {
            cursor: pointer;
            color: orange;
        }
    }

    &--footer {
        z-index: 1;
        display: flex;
        width: 100%;
        position: fixed;
        bottom: 45px;
        align-items: center;
        p {
            color: white;
        }
        p:hover {
            cursor: pointer;
            color: orange;
        }

        &--cookies {
            display: flex;
            justify-content: center;
            width: 100%;
            align-items: center;
            p {
                font-size: 14px;
                margin: 0px;
            }
            p:hover {
                color: white;
                cursor: auto;
            }

            &--here {
                border-bottom: 1px solid white;
            }

            &--here:hover {
                cursor: pointer;
            }

            &--accept {
                text-align: center;
                margin: 10px;
                border: 1px solid white;
            }

            &--accept:hover {
                cursor: pointer;
                border: 1px solid orange;
                p{
                    color: orange;
                }
            }
                

        }
    }

    &--gdpr {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 260px;
        height: 260px;
        background-color: rgba($color: #000000, $alpha: .9);
        position: fixed;
        right: 0px;
        bottom: 0px;
        padding: 20px;
        text-align: center;
        p {
            font-size: 14px;
            margin: 0px;
        }
        p:hover {
            color: white;
            cursor: auto;
        }

        &--accept {
            text-align: center;
            margin: 10px;
            p:hover{
                cursor: pointer;
                color: orange;
            }
        }
    }

    &--sidebar {
        height: 98%;
        width: 98%;
        &--content {
            width: 98%;
            height: 98%;
        }
    }

    @media only screen and (max-width: 1280px) {
        &--bar {
            &--content {
                &--closeMenu {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    margin: 0px;
                    img {
                        width: 40px;
                        margin: 10px;
                        margin-left: 0px;
                    }
                    img:hover {
                        cursor: pointer;
                    }
                }

                &--emptyrow {
                    height: 40px;
                }

                &--listElem {
                    font-size: 20px;
                    letter-spacing: 10px;
                }

                &--media {
                    &--social1 {                    
                        p {
                            justify-self: center;
                            align-self: center;
                            margin: 0px;
                            font-size: 10px;
                            letter-spacing: 4px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 780px) {
        &--logos {
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }

        &--menu {
            position: relative;
        }
    
        &--logo {
            position: relative;
        }

        &--questions {
            display: flex;
            justify-content: flex-end;
            margin-right: 7%;
            width: 100%;
            p {
                z-index: 3;
                font-size: 14px;
                letter-spacing: 10px;
                font-weight: bold;
            }
        }
    }
    @media only screen and (max-width: 576px) {
        &--gdpr {
            bottom: 0px;
            right: 50px;
            width: 250px;
            height: 250px;
        }

        &--logos {
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }

        &--menu {
            position: relative;
        }
    
        &--logo {
            position: relative;
        }

        &--footer {
            display: none;
        }
        &--bar {
            &--content {
                &--emptyrow {
                    height: 30px;
                }
                &--row {
                    margin: 0px;
                    margin-left: 10px;
                    padding: 0px;
                }
                &--listElem {
                    font-size: 18px;
                    letter-spacing: 6px;
                }
                &--media {
                    &--terms {
                        flex-direction: column;
                        width: 300px;
                    }
        
                    &--social1 {
                        flex-direction: column;
                        p {
                            margin-top: 20px;
                            margin-bottom: 20px;
                            letter-spacing: 6px;
                            width: 70%;
                        }
                    }
                }
            }
        }
    }
}
