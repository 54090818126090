.Join {
    position: relative;
    &--close {
        z-index: 1;
        position: absolute;
        left: 2%;
        top: 2%;
        width: 5%;
        transition: all 1s;
        animation: example2;
        animation-duration: 3s;
    }

    &--close:hover {
        cursor: pointer;
        width: 4%;
        left: 2.5%;
        top: 2.5%;
    }

    &--center {
        animation: example2;
        animation-duration: 3s;
        display: flex;
        align-items: center;
        justify-content: center;
        &--join {
            position: absolute;
            width: 500px;
            height: 500px;
            margin-top: -70px;
            -moz-opacity:0 ;
            opacity: 0;
        }

        &--join:hover {
            cursor: pointer;
        }

        &--wrapper {
            width: 500px;
            height: 500px;
        }

        &--joinOverlay {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            background-color: #231F20;
            text-align: center;
            width: 500px;
            height: 500px;
            margin-top: -70px;
            p {
                display: flex;
                font-size: 30px;
                letter-spacing: 7px;
                color: white;
            }
        }

        @media (max-width: 1280px) {
            
            &--wrapper {
                height: 400px;
                width: 400px;
            }
            &--join, &--joinOverlay {
                width: 400px;
                height: 400px;
                margin: 0px;
            }
        }

        @media (max-width: 576px) {
            height: 350px;
            display: flex;
            justify-content: center;
            margin: 0px;
            padding: 0px;
            
            &--wrapper {
                height: 300px;
                width: 300px;
            }
            &--join, &--joinOverlay {
                width: 300px;
                height: 300px;
                margin: 0px;
            }
        }

    }

    &--info {
        height: 100vh;
        background-color: white;
        padding-left: 10%;
        padding-top: 10%;
        padding-right: 5%;
        &--firstText {
            animation: example2;
            animation-duration: 3s;
            font-size: 54px;
            letter-spacing: 5.3px;
            font-weight: lighter;
        }
        &--secondText {
            animation: example2;
            animation-duration: 3s;
            font-size: 50px;
            letter-spacing: -3.75px;
            font-weight: bold;
        }
        &--thirdText {
            animation: example2;
            animation-duration: 3s;
            font-size: 18px;
            font-weight: lighter;
        }
        &--rectangle {
            animation: example2;
            animation-duration: 3s;
            width: 17px;
            height: 17px;
            background-color: #FFB000;
        }

        @media (max-width: 1200px) {
            height: 500px;
        }

        @media (max-width: 576px) {
            height: 500px;
            padding: 36px;
            padding-top: 15%;
            &--firstText {
                font-size: 32px;
                line-height: 32px;
                letter-spacing: 3.14px;
                font-weight: lighter;
            }
            &--secondText {
                font-size: 29px;
                line-height: 29px;
                letter-spacing: -2.17px;
                font-weight: bold;
            }
        }
    }

    &--right {
        animation: example2;
        animation-duration: 3s;
        background-color: white;
        height: 100vh;
        padding-left: 5%;
        padding-right: 10%;
        padding-top: 10%;
        
        &--form {
            display: flex;
            flex-direction: column;
        }

        &--input {
            border: none;
            border-bottom: 1px solid #FFB000;
            margin-bottom: 20px;
            height: 50px;
            background-color: rgba($color: #000000, $alpha: 0);
            color: black;
            font-size: 24px;
        }

        &--message {
            border: 1px solid #FFB000;
            margin-bottom: 20px;
            height: 150px;
            background-color: rgba($color: #000000, $alpha: 0);
            color: black;
            font-size: 24px;
            textarea  {
                rows: 5;
            }
        }

        &--submit {
            border: none;
            align-self: center;
            background-color: rgba($color: #000000, $alpha: 0);
            font-size: 35px;
            letter-spacing: 35px;
            color: black;
            text-align: center;
            text-indent: 35px;
        }

        &--submit:hover {
            cursor: pointer;
            color: orange;
        }

        @media (max-width: 576px) {
            height: 600px;
            padding: 36px;
            &--submit {
                font-size: 25px;
                letter-spacing: 25px;
                text-indent: 25px;
            }
        }
    
    }

    @media (max-width: 1200px) {
        &--close {
            z-index: 1;
            position: absolute;
            left: 85%;
            top: 2%;
            width: 11%;
        }
        
        &--close:hover {
            cursor: pointer;
            position: absolute;
            left: 85%;
            top: 2%;
            width: 10%;
        }

    }
}